<script lang="ts" setup>
import LabelText from '~/components/form-elements/LabelText.vue'
import type { SelectOption } from '~/types/form-elements'

const model = defineModel<any>()

const emit = defineEmits<{
  (e: 'selected', x: SelectOption): void
}>()

withDefaults(
  defineProps<{
    icon?: string
    isBorder?: boolean
    isRadioButton?: boolean
    labelText?: string | null
    options: SelectOption[]
    placeholder?: string | null
  }>(),
  { icon: 'outlined/arrow-down-gray', isBorder: true, isRadioButton: true, labelText: null, placeholder: null }
)

const dropdownStatus = ref(false)
const dropdownRef = ref<HTMLElement | null>(null)

function closeDropdown () {
  dropdownStatus.value = false
}

function handleClickOutside (event: MouseEvent) {
  if (dropdownRef.value && !dropdownRef.value.contains(event.target as Node)) {
    closeDropdown()
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<template>
  <div ref="dropdownRef">
    <LabelText v-if="labelText" class="mb-1" :value="labelText" />

    <div class="relative inline-block text-left">
      <button
        class="
          inline-flex w-full items-center justify-between
          rounded-2xl bg-transparent
          px-3 py-1 text-[15px] text-main-silver
          sm:py-3
        "
        :class="{ '!border-main-green': dropdownStatus, 'border border-gray-200': isBorder }"
        @click.prevent="dropdownStatus = !dropdownStatus"
      >
        <span class="whitespace-nowrap pr-1 text-sm leading-normal tracking-tight text-main-dark sm:text-base">
          {{ model?.name ?? placeholder }}
        </span>

        <SvgIcon class="!size-6" :name="icon" />
      </button>

      <div
        v-if="dropdownStatus"
        class="absolute right-0 z-50 mt-2 w-full min-w-40 origin-top-right rounded-xl bg-white shadow-lg"
      >
        <div class="py-1">
          <a
            v-for="x in options"
            :key="x.value"
            class="
              flex items-center whitespace-nowrap px-4 py-2
              text-sm leading-normal tracking-tight text-main-dark !no-underline hover:bg-main-gray-low
              sm:text-base
            "
            :class="{ 'text-main-green': x === model }"
            href="#"
            @click.prevent="model = x; dropdownStatus = false; emit('selected', x)"
          >
            <div v-if="isRadioButton" class="mr-3">
              <SvgIcon v-if="x === model" class="!size-5" name="outlined/leaky-circle" />
              <SvgIcon v-else class="!size-5" name="outlined/circle" />
            </div>

            <span class="block">
              {{ x.name }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
